import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { APP_CONFIG, IAppConfig } from 'src/app/core/services/app.config';
import { AuthService } from 'src/app/core/services/auth.service';
import { BenefitState } from 'src/app/state/benefit/benefit.reducer';
import { CartState } from 'src/app/state/cart/cart.reducer';
import * as BenefitActions from '../../../../state/benefit/benefit.actions';
import { BasicBenefitsTextResponse } from 'src/models/basic-benefits-text';
import { ClientService } from 'src/app/core/services/client.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  categories = [];

  authUser: any;
  
  basicBenefitsTextModel = new BasicBenefitsTextResponse();
  basicBenefitsText: string;
  public loading = false;

  constructor(
    private store: Store<{ benefit: BenefitState, cart: CartState }>,
    private authService: AuthService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) public appConfig: IAppConfig,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    this.store.dispatch(new BenefitActions.GetData({
      userId: this.authUser.userId
    }));
    this.getBasicBenefitsTextByCompany();
    this.store.select(state => state && state.benefit && state.benefit.data).subscribe(x => {
      const data = cloneDeep(x);
      // const flexible = x.benefitCategories.findIndex(x => x.Name == "TEMEL YAN HAKLAR");
      // const key = (flexible == -1) ? 1 : flexible;
      let mainBenefitName = this.translateService.instant('mainBenefits');
      this.categories =
        data && data.benefitCategories && data.benefitCategories.length && data.benefitCategories.find(x => x.Name.toLowerCase() == mainBenefitName.toLowerCase()).BenefitCategories || [];
    });    
  }

  getBasicBenefitsTextByCompany() {
    this.loading = true;
    this.clientService.getBasicBenefitsTextByCompany(this.authUser.companyId).subscribe(data => {
      this.basicBenefitsTextModel = data as BasicBenefitsTextResponse;
      this.loading = false;
      this.basicBenefitsText = this.basicBenefitsTextModel ? this.basicBenefitsTextModel.Description : "";
     })    
  } 
}
