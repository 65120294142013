import { cloneDeep } from 'lodash';
import { BenefitResponse } from 'src/models/benefit';
import { BenefitCategoryResponse } from 'src/models/benefit-category';
import * as BenefitActions from './benefit.actions';

export interface BenefitState {
  benefits: any[];
  benefitCategories: BenefitCategoryResponse[];
  benefitTypes: any[];
  mainBenefits: BenefitResponse[];
  data: any;
  loading: boolean;
  addingBenefit: boolean;
  languageResourcesLoading: boolean;
  languageResourcesData: any;
}

export const initialState: BenefitState = {
  benefits: null,
  benefitCategories: null,
  benefitTypes: null,
  mainBenefits: null,
  data: null,
  loading: false,
  addingBenefit: false,
  languageResourcesLoading: false,
  languageResourcesData: null
};

export function reducer(state = initialState, action: BenefitActions.Actions): BenefitState {
  switch (action.type) {
    case BenefitActions.ActionTypes.GetMainBenefits: {
      return {
        ...state,
        mainBenefits: []
      };
    }
    case BenefitActions.ActionTypes.LoadMainBenefits: {
      return {
        ...state,
        mainBenefits: action.payload.mainBenefits
      };
    }
    case BenefitActions.ActionTypes.GetBenefitCategories: {
      return {
        ...state,
        benefitCategories: []
      };
    }
    case BenefitActions.ActionTypes.LoadBenefitCategories: {
      return {
        ...state,
        benefitCategories: action.payload.benefitCategories ? action.payload.benefitCategories : []
      };
    }
    case BenefitActions.ActionTypes.GetBenefitTypes: {
      return {
        ...state,
        benefitTypes: []
      };
    }
    case BenefitActions.ActionTypes.LoadBenefitTypes: {
      return {
        ...state,
        benefitTypes: action.payload.benefitTypes ? action.payload.benefitTypes : []
      };
    }
    case BenefitActions.ActionTypes.GetData: {
      return {
        ...state,
        loading: true
      };
    }
    case BenefitActions.ActionTypes.LoadData: {
      const data = cloneDeep(action.payload.data);
      if (data.alert) {
        alert(data.alert);
      }
      data.unique = new Date().getTime();
      return {
        ...state,
        data,
        loading: false,
        addingBenefit: false
      };
    }
    case BenefitActions.ActionTypes.GetLanguageResources: {
      return {
        ...state,
        languageResourcesLoading: true
      };
    }

    case BenefitActions.ActionTypes.LoadLanguageResources: {
      return {
        ...state,
        languageResourcesLoading: false,
        languageResourcesData: action.payload.languageResourcesData
      };
    }

    case BenefitActions.ActionTypes.ToggleAddingBenefit: {
      return {
        ...state,
        addingBenefit: !state.addingBenefit
      };
    }
    case BenefitActions.ActionTypes.ResetState: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
