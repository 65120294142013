import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as BenefitReducer from './benefit/benefit.reducer';
import * as CartReducer from './cart/cart.reducer';
import * as AuthReducer from './auth/auth.reducer';

export interface State {
    benefit: BenefitReducer.BenefitState;
    cart: CartReducer.CartState;
    auth: AuthReducer.AuthState
}

export const reducers: ActionReducerMap<State> = {
    benefit: BenefitReducer.reducer,
    cart: CartReducer.reducer,
    auth: AuthReducer.reducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
