import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthTokenType } from 'src/models/auth-token-type';
import { APP_CONFIG, IAppConfig } from './app.config';
import { TokenStoreService } from './token-store.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private headers = new HttpHeaders();
  private apiPath: string;
  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private http: HttpClient,
    private tokenStoreService: TokenStoreService

  ) { }

  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }

  getLanguageByCompanyId(companyId: any) {
    this.apiPath = `${this.appConfig.apiEndpoint}/Language/getLanguages?companyId=${companyId}`;
    return this.http.get(this.apiPath, { headers: this.headers });
  }

  getLanguageResourcesByLanguage(languageId: any) {
    this.apiPath = `${this.appConfig.apiEndpoint}/LanguageResource/getLanguageResourcesByLanguage?languageId=${languageId}`;
    return this.http.get(this.apiPath, { headers: this.headers });
  }

  getLanguage(id: any) {
    this.apiPath = `${this.appConfig.apiEndpoint}/Language/getLanguage?id=${id}`;
    return this.http.get(this.apiPath, { headers: this.headers });
  }

  getUserLanguage(userId: any) {
    this.apiPath = `${this.appConfig.apiEndpoint}/Language/getUserLanguage?userId=${userId}`;
    return this.http.get(this.apiPath, { headers: this.headers });

  }

  ChangeLanguage(req): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/user/ChangeLanguage`;
    return this.http.post(this.apiPath,req, { headers: this.headers }).pipe(map(
      res => {
        if (res) {
          return res;
        }
        return ;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }
}
