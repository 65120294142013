
export class AnnouncementResponse {
    Id: string;
    CompanyId: string;
    Name: string;
    Description: string;
    Status: number;
}

export class AnnouncementRequest {
    Id: string;
    CompanyId: string;
    Name: string;
    Description: string;
    Status: number;
}