import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageSettingsComponent } from '../layout/components/home-page-settings/home-page-settings.component';
import { HomePageComponent } from './home-page/home-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageSettingsComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: '',
        component: HomePageComponent,
        pathMatch: 'full'
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
