import { Injectable, Injector, Inject } from '@angular/core';
import { APP_CONFIG, IAppConfig } from './app.config';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ApiConfigService {

  private config: IApiConfig | null = null;

  constructor(
    private injector: Injector,
    @Inject(APP_CONFIG) private appConfig: IAppConfig) { }

    async loadApiConfig(): Promise<any> {
      const jsonData = await this.injector.get<HttpClient>(HttpClient).get('assets/app.config.json').toPromise();
      this.appConfig.apiEndpoint = jsonData['apiEndpoint'];
      this.appConfig.apiSettingsPath = jsonData['apiSettingsPath'];
      this.appConfig.storageUrl = jsonData['storageUrl'];
      const http = this.injector.get<HttpClient>(HttpClient);
      const url = `${this.appConfig.apiEndpoint}/${this.appConfig.apiSettingsPath}`;
      return await http.get<IApiConfig>(url)
        .toPromise()
        .then(config => {
          this.config = config;
        })
        .catch(err => {
          console.error(`Failed to loadApiConfig(). Make sure ${url} is accessible.`, this.config);
          return Promise.reject(err);
        });
    }
  
    get configuration(): IApiConfig {
      if (!this.config) {
        throw new Error("Attempted to access configuration property before configuration data was loaded.");
      }
      return this.config;
    }
  }
  
  export interface IApiConfig {
    loginPath: string;
    logoutPath: string;
    refreshTokenPath: string;
    accessTokenObjectKey: string;
    refreshTokenObjectKey: string;
    adminRoleName: string;
  }
  