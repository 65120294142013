import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from 'rxjs/internal/Observable';
import { CompanyResponse } from "src/models/company";
import { APP_CONFIG, IAppConfig } from "./app.config";

@Injectable()
export class CompanyService {

  private headers=new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
  ) 
  {
    this.headers = this.getHeader();
  }


  getHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json"
    });
  }
  
  getCompanies(tcNo : string): Observable<CompanyResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/account/getCompanyByTCNo?tcNo=${tcNo}`;
    return this.http.get<CompanyResponse[]>(this.apiPath, {headers: this.headers});
  }
 
}