import { AuthUser } from 'src/models/auth-user';
import * as AuthActions from './auth.actions';

export interface AuthState {
  user: AuthUser;
  loginSuccess: boolean;
  errorMessage: string;
  defaultLanguage: any;
}

export const initialState: AuthState = {
  user: null,
  loginSuccess: null,
  errorMessage: '',
  defaultLanguage: null
};

export function reducer(state = initialState, action: AuthActions.Actions): AuthState {
  switch (action.type) {
    case AuthActions.ActionTypes.StoreAuthUser: {
      return {
        ...state,
        user: action.payload.authUser,
        errorMessage: ''
      };
    }
    case AuthActions.ActionTypes.Logout: {
      return {
        ...initialState,
        errorMessage: ''
      };
    }
    case AuthActions.ActionTypes.ErrorOccurred: {
      return {
        ...state,
        errorMessage: action.payload.message
      };
    }
    case AuthActions.ActionTypes.SetDefaultLanguage: {
      const code = action.payload.defaultLanguage.LanguageAbbreviations.toLowerCase();
      localStorage.setItem('userLang', code);
      return {
        ...state,
        defaultLanguage: action.payload.defaultLanguage
      };
    }
    default:
      return state;
  }
}
