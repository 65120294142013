import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, IAppConfig } from 'src/app/core/services/app.config';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClientService } from 'src/app/core/services/client.service';
import { BenefitService } from 'src/app/services/benefit.service';
import { BenefitState } from 'src/app/state/benefit/benefit.reducer';
import * as BenefitActions from '../../../../state/benefit/benefit.actions';
import { FlexibleBenefitsTextResponse } from 'src/models/flexible-benefits-text';

@Component({
  selector: 'app-flexible-right',
  templateUrl: './flexible-right.component.html',
  styleUrls: ['./flexible-right.component.scss']
})
export class FlexibleRightComponent implements OnInit {
  categories: any[] = [];
  authUser: any;
  haveBenefits : true;
  
  flexibleBenefitsTextModel = new FlexibleBenefitsTextResponse();
  flexibleBenefitsText: string;
  maxGiftLimit: any;
  monthCount:any
  monthlyLimit:any
  isfollowingMonths:boolean
  public loading = false;

  constructor(
    private store: Store<{ benefit: BenefitState }>,
    private benefitService: BenefitService,
    private authService: AuthService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    this.store.dispatch(new BenefitActions.GetData({
      userId: this.authUser.userId
    }));
    this.getFlexibleBenefitsTextByCompany();
    this.store.select(state => state && state.benefit && state.benefit.data).subscribe(data => {
      // const flexible = data.benefitCategories.findIndex(x => x.Name == "ESNEK YAN HAKLAR");
      // const key = (flexible == -1) ? 0 : flexible;
      let flexibleBenefitName = this.translateService.instant('flexibleBenefits');
      this.categories = (data && data.benefitCategories
        && data.benefitCategories.length && data.benefitCategories.find(x => x.Name.toLowerCase() == flexibleBenefitName.toLowerCase()).BenefitCategories) || [];
    });
    this.getGiftCardLimit();
    console.log(this.categories)
  }
  getGiftCardLimit(){
    this.benefitService.getUserGiftCardRule(this.authUser.userId).subscribe(data => {
      this.maxGiftLimit = data.MaxLimit * data.MonthLimit;
      this.monthCount = data.MonthLimit;
      this.monthlyLimit = data.MaxLimit;
      this.clientService.getCompanyGiftCardFM(this.authUser.companyId).subscribe(data => {
        this.isfollowingMonths = data;
      })
    })
  }
  getFlexibleBenefitsTextByCompany() {
    this.loading = true;
    this.clientService.getFlexibleBenefitsTextByCompany(this.authUser.companyId).subscribe(data => {
      this.flexibleBenefitsTextModel = data as FlexibleBenefitsTextResponse;
      this.loading = false;
      this.flexibleBenefitsText = this.flexibleBenefitsTextModel ? this.flexibleBenefitsTextModel.Description : "";
    })    
  }  
}
