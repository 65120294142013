import { Action } from '@ngrx/store';

export enum ActionTypes {
    GetBenefits = '[BENEFIT] GET_BENEFITS',
    LoadBenefits = '[BENEFIT] LOAD_BENEFITS',
    GetMainBenefits = '[BENEFIT] GET_MAIN_BENEFITS',
    LoadMainBenefits = '[BENEFIT] LOAD_MAIN_BENEFITS',
    GetBenefitCategories = '[BENEFIT] GET_BENEFIT_CATEGORIES',
    LoadBenefitCategories = '[BENEFIT] LOAD_BENEFIT_CATEGORIES',
    GetBenefitTypes = '[BENEFIT] GET_BENEFIT_TYPES',
    LoadBenefitTypes = '[BENEFIT] LOAD_BENEFIT_TYPES',
    GetData = '[BENEFIT] GET_DATA',
    LoadData = '[BENEFIT] LOAD_DATA',
    ToggleAddingBenefit = '[BENEFIT] TOGGLE_ADDING_BENEFIT',
    ResetState = '[BENEFIT] RESET_STATE',
    GetLanguageResources = '[BENEFIT] GET_LANGUAGE_RESOURCES',
    LoadLanguageResources = '[BENEFIT] LOAD_LANGUAGE_RESOURCES'
}

export class GetBenefits implements Action {
    readonly type = ActionTypes.GetBenefits;
    constructor() { }
}

export class LoadBenefits implements Action {
    readonly type = ActionTypes.LoadBenefits;
    constructor(public payload: { benefits: any[] }) { }
}

export class GetMainBenefits implements Action {
    readonly type = ActionTypes.GetMainBenefits;
    constructor(public payload: { companyId: number }) { }
}

export class LoadMainBenefits implements Action {
    readonly type = ActionTypes.LoadMainBenefits;
    constructor(public payload: { mainBenefits: any[] }) { }
}

export class GetBenefitCategories implements Action {
    readonly type = ActionTypes.GetBenefitCategories;
    constructor() { }
}

export class LoadBenefitCategories implements Action {
    readonly type = ActionTypes.LoadBenefitCategories;
    constructor(public payload: { benefitCategories: any[] }) { }
}

export class GetBenefitTypes implements Action {
    readonly type = ActionTypes.GetBenefitTypes;
    constructor() { }
}

export class LoadBenefitTypes implements Action {
    readonly type = ActionTypes.LoadBenefitTypes;
    constructor(public payload: { benefitTypes: any[] }) { }
}

export class GetData implements Action {
    readonly type = ActionTypes.GetData;
    constructor(public payload: { userId: string }) { }
}

export class LoadData implements Action {
    readonly type = ActionTypes.LoadData;
    constructor(public payload: { data: any }) { }
}

export class ToggleAddingBenefit implements Action {
    readonly type = ActionTypes.ToggleAddingBenefit;
    constructor() { }
}

export class ResetState implements Action {
    readonly type = ActionTypes.ResetState;
    constructor() { }
}

export class GetLanguageResources implements Action {
    readonly type = ActionTypes.GetLanguageResources;
    constructor(public payload: { Id: any }) { }
}

export class LoadLanguageResources implements Action {
    readonly type = ActionTypes.LoadLanguageResources;
    constructor(public payload: { languageResourcesData: any }) { }
}

export type Actions =
    GetBenefits |
    LoadBenefits |
    GetMainBenefits |
    LoadMainBenefits |
    GetBenefitCategories |
    LoadBenefitCategories |
    GetBenefitTypes |
    LoadBenefitTypes |
    GetData |
    LoadData |
    ToggleAddingBenefit |
    ResetState |
    GetLanguageResources |
    LoadLanguageResources;
