import * as CartActions from './cart.actions';
export interface CartState {
  shoppingCart: any[];
  showMobileCart: boolean;
}

export const initialState: CartState = {
  showMobileCart: false,
  shoppingCart: [],
};

export function reducer(state = initialState, action: CartActions.Actions): CartState {
  switch (action.type) {
    case CartActions.ActionTypes.AddBenefitToCart: {
      if (state.shoppingCart.findIndex(x => x.Id === action.payload.benefit.Id) !== -1) {
        return {
          ...state,
          shoppingCart: state.shoppingCart.filter(x => x.Id !== action.payload.benefit.Id)
        };
      }
      return {
        ...state,
        shoppingCart: [...state.shoppingCart.filter(x => x.Id !== action.payload.benefit.Id), action.payload.benefit]
      };
    }
    case CartActions.ActionTypes.RemoveBenefitFromCart: {
      return {
        ...state,
        shoppingCart: state.shoppingCart.filter(x => x.Id !== action.payload.benefitId)
      };
    }
    case CartActions.ActionTypes.ToggleMobileCart: {
      return {
        ...state,
        showMobileCart: !state.showMobileCart
      };
    }
    case CartActions.ActionTypes.PopulateCartWithMainBenefits: {
      if (state.shoppingCart.length === 0 && action.payload.benefits && action.payload.benefits.length !== 0) {
        return {
          ...state,
          shoppingCart: action.payload.benefits
        };
      }
      return state;
    }
    default:
      return state;
  }
}
