import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, finalize, map } from "rxjs/operators";

import { ApiConfigService } from "./api-config.service";
import { APP_CONFIG, IAppConfig } from "./app.config";
import { TokenStoreService } from "./token-store.service";
import { AuthTokenType } from 'src/models/auth-token-type';
import { PageResponse } from 'src/models/page';
import { Observable } from 'rxjs';

@Injectable()
export class PageService {

  private headers=new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) 
  {
    this.headers = this.getBearerAuthHeader();
  }


  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }

  getPages(companyId: string, type: string): Observable<PageResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/page/getPages?companyId=${companyId}&type=${type}`;
    return this.http.get<PageResponse[]>(this.apiPath, {headers: this.headers});
  }

getHelpPages(companyId: string, type: string,languageAbb: string): Observable<PageResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/page/getPages?companyId=${companyId}&type=${type}&languageAbb=${languageAbb}`;
    return this.http.get<PageResponse[]>(this.apiPath, {headers: this.headers});
  }
}

