import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{ 
		path: '', 
		redirectTo: 'home', 
		pathMatch: 'full' 
	},
	{
		path: 'profile',
		loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
	 },
	 {
		path: 'survey/:id/:code',
		loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule)
	 },
	 {
		path: 'cart',
		loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule)
	  },
	  {
		path: 'home',
		loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
	  },
	  {
		path: '',
		loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
	  },
	  {
		path: 'Main',
		loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)
	  },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash:true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
