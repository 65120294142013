import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AuthState } from 'src/app/state/auth/auth.reducer';
import { AuthTokenType } from 'src/models/auth-token-type';
import { AuthUser } from 'src/models/auth-user';
import { Credentials } from 'src/models/credentials';
import * as AuthActions from '../../state/auth/auth.actions';
import { ApiConfigService } from './api-config.service';
import { APP_CONFIG, IAppConfig } from './app.config';
import { RefreshTokenService } from './refresh-token.service';
import { TokenStoreService } from './token-store.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthService {

  private authStatusSource = new BehaviorSubject<boolean>(false);
  authStatus$ = this.authStatusSource.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService,
    private refreshTokenService: RefreshTokenService,
    private translate:TranslateService,
    private store: Store<{ auth: AuthState }>
  ) {
    this.updateStatusOnPageRefresh();
    this.refreshTokenService.scheduleRefreshToken(this.isAuthUserLoggedIn());
  }

  login(credentials: Credentials): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    return this.http
      .post(`${this.appConfig.apiEndpoint}/${this.apiConfigService.configuration.loginPath}`,
        credentials, { headers })
      .pipe(
        map((response: any) => {
          this.tokenStoreService.setRememberMe(credentials.rememberMe);
          if (!response) {
            console.error('There is no `{\'' + this.apiConfigService.configuration.accessTokenObjectKey +
              '\':\'...\',\'' + this.apiConfigService.configuration.refreshTokenObjectKey + '\':\'...value...\'}` response after login.');
            this.authStatusSource.next(false);
            this.refreshTokenService.scheduleRefreshToken(true);
            return false;
          }
          this.tokenStoreService.storeLoginSession(response);
          const authUser = this.getAuthUser();
          if (authUser.companyId != '0') {
            if (new Date(authUser.expirationDate) < new Date()) {
              this.logout(false);
              throw new HttpErrorResponse({ statusText: this.translate.instant("auth.licenceExpired") });
            }
          }
          localStorage.setItem('userCompleted', authUser.completed.toString());
          this.refreshTokenService.scheduleRefreshToken(true);
          this.authStatusSource.next(true);
          return true;
        }),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }

  logout(navigateToHome: boolean): void {
    localStorage.removeItem('userLang');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const logoutUser = { refreshToken: this.tokenStoreService.getRawAuthToken(AuthTokenType.RefreshToken) };
    this.http
      .post(`${this.appConfig.apiEndpoint}/${this.apiConfigService.configuration.logoutPath}`,
        logoutUser, { headers })
      .pipe(
        map(response => response || {}),
        catchError((error: HttpErrorResponse) => throwError(error)),
        finalize(() => {
          this.store.dispatch(new AuthActions.Logout());
          this.tokenStoreService.deleteAuthTokens();
          this.refreshTokenService.unscheduleRefreshToken();
          this.authStatusSource.next(false);
          if (navigateToHome) {
            if(this.router.url.split('/')[1] == 'survey') this.router.navigate([this.router.url]);
            else this.router.navigate(['/login']);
          }
        }))
      .subscribe(result => {
      });
  }

  isAuthUserLoggedIn(): boolean {
    return this.tokenStoreService.hasStoredAccessAndRefreshTokens() &&
      !this.tokenStoreService.isAccessTokenTokenExpired();
  }

  getAuthUser(): AuthUser | null {
    if (!this.isAuthUserLoggedIn()) {
      return null;
    }

    const decodedToken = this.tokenStoreService.getDecodedAccessToken();
    if (decodedToken && decodedToken.ExpirationDate) {

        // const result = decodedToken.ExpirationDate.match(/<(.*?)>(.*?)<\/(.*?)>/g).map((val) => val.replace(/<\/?(.*?)>/g, ''));
        // decodedToken.UserLimit = result[1];
        // decodedToken.ExpirationDate = result[2];
      
    }
    const roles = this.tokenStoreService.getDecodedTokenRoles();
    return Object.freeze({
      userId: decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
      companyId: decodedToken.CompanyId,
      parentCompanyId: decodedToken.ParentCompanyId,
      tckno: decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
      fullName: decodedToken.FullName,
      companyLogo: decodedToken.CompanyLogo,
      companyName: decodedToken.CompanyName,
      userPhoto: decodedToken.UserPhoto,
      expirationDate: decodedToken.ExpirationDate,
      userLimit: decodedToken.UserLimit,
      completed: decodedToken.Completed,
      loggedinBefore: decodedToken.LoggedinBefore,
      simulationPercentage: decodedToken.SimulationPercentage,
      mainHealthPercentage: decodedToken.MainHealthPercentage,
      partnerHealthPercentage: decodedToken.PartnerHealthPercentage,
      childHealthPercentage: decodedToken.ChildHealthPercentage,
      roles,
      languageId: decodedToken.Language
    });
  }

  isAuthUserInRoles(requiredRoles: string[]): boolean {
    const user = this.getAuthUser();
    if (!user || !user.roles) {
      return false;
    }

    return requiredRoles.some(requiredRole => {
      if (user.roles) {
        return user.roles.indexOf(requiredRole.toLowerCase()) >= 0;
      } else {
        return false;
      }
    });
  }

  isAuthUserInRole(requiredRole: string): boolean {
    return this.isAuthUserInRoles([requiredRole]);
  }

  private updateStatusOnPageRefresh(): void {
    this.authStatusSource.next(this.isAuthUserLoggedIn());
  }
}
