import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) { }

  @Effect() getAuthUser =
    this.actions$.pipe(
      ofType<AuthActions.GetAuthUser>(
        AuthActions.ActionTypes.GetAuthUser
      ),
      mergeMap((action) => {
        const authUser = this.authService.getAuthUser();
        if (authUser) {
          return of(new AuthActions.AuthUserLoaded({ authUser }));
        } else {
          return EMPTY;
        }
      }
      )
    );

  @Effect() authUserLoaded =
    this.actions$.pipe(
      ofType<AuthActions.AuthUserLoaded>(
        AuthActions.ActionTypes.AuthUserLoaded
      ),
      mergeMap((action) => {
        if (action.payload.authUser) {
          sessionStorage.setItem('userId', action.payload.authUser.userId);
          sessionStorage.setItem('companyId', action.payload.authUser.companyId);
          //console.log(action.payload.authUser)
          return [

            new AuthActions.StoreAuthUser({ authUser: action.payload.authUser })
          ];
        } else {
          return EMPTY;
        }
      }
      )
    );

  @Effect() login$ =
    this.actions$.pipe(
      ofType<AuthActions.Login>(
        AuthActions.ActionTypes.Login
      ),
      mergeMap((action) => {
        return this.authService.login(action.payload.credentials).pipe(
          mergeMap((success: any) => {
            if (success) { 
               if(this.router.url.split('/')[1] == 'survey') this.router.navigate([this.router.url+'/1']);
               else this.router.navigate(['/home']);
              return of(new AuthActions.GetAuthUser());
            } else {
            }
          }),
          catchError(message => {
            return of(new AuthActions.ErrorOccurred({ message: 'login.invalidLogin' }));
          })
        );
      })
    );

}
