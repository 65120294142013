import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClientService } from 'src/app/core/services/client.service';
import { BenefitService } from 'src/app/services/benefit.service';
import * as AuthActions from '../auth/auth.actions';
import { LanguageService } from './../../core/services/language.service';
import * as BenefitActions from './benefit.actions';

@Injectable()
export class BenefitEffects {

  public getDataWithCalcError = false;

  constructor(
    private actions$: Actions,
    private benefitService: BenefitService,
    private authService: AuthService,
    private clientService: ClientService,
    private languageService: LanguageService
  ) { 
    this.getDataWithCalcError = false;
  }


  @Effect() getBenefits =
    this.actions$.pipe(
      ofType<BenefitActions.GetBenefits>(
        BenefitActions.ActionTypes.GetBenefits
      ),
      mergeMap((action) =>
        this.benefitService.getBenefits().pipe(
          map(
            benefits => new BenefitActions.LoadBenefits({
              benefits
            })
          ),
          catchError((e) => {
            return EMPTY;
          })
        )
      )
    );

  @Effect() getBenefitCategories =
    this.actions$.pipe(
      ofType<BenefitActions.GetBenefitCategories>(
        BenefitActions.ActionTypes.GetBenefitCategories
      ),
      mergeMap((action) => {
        const user = this.authService.getAuthUser();
        return this.benefitService.allCategories(
          user.companyId
        ).pipe(map(benefitCategories => new BenefitActions.LoadBenefitCategories({
          benefitCategories
        })), catchError((e) => {
          return EMPTY;
        }));
      }
      )
    );

  @Effect() getBenefitTypes =
    this.actions$.pipe(
      ofType<BenefitActions.GetBenefitTypes>(
        BenefitActions.ActionTypes.GetBenefitTypes
      ),
      mergeMap((action) =>
        this.benefitService.getBenefitTypes().pipe(
          map(
            benefitTypes => new BenefitActions.LoadBenefitTypes({
              benefitTypes
            })
          ),
          catchError((e) => {
            return EMPTY;
          })
        )
      )
    );

  @Effect() getMainBenefits =
    this.actions$.pipe(
      ofType<BenefitActions.GetMainBenefits>(
        BenefitActions.ActionTypes.GetMainBenefits
      ),
      mergeMap((action) =>
        this.benefitService.getMainBenefits(action.payload.companyId).pipe(
          map(
            mainBenefits => new BenefitActions.LoadMainBenefits({
              mainBenefits
            })
          ),
          catchError((e) => {
            return EMPTY;
          })
        )
      )
    );

  @Effect() GetData =
    this.actions$.pipe(
      ofType<BenefitActions.GetData>(
        BenefitActions.ActionTypes.GetData
      ),
      mergeMap((action) =>
        this.clientService.getDataWithCalc(action.payload.userId).pipe(
          map(
            data => new BenefitActions.LoadData({ data })        
          ),
          catchError((e) => {
            this.getDataWithCalcError = true;
            return EMPTY;
          })
        )
      )
    );

  @Effect() logout =
    this.actions$.pipe(
      ofType<AuthActions.Logout>(
        AuthActions.ActionTypes.Logout
      ),
      mergeMap((action) =>
        [new BenefitActions.ResetState()]
      )
    );

  @Effect() getLanguageResources =
    this.actions$.pipe(
      ofType<BenefitActions.GetLanguageResources>(
        BenefitActions.ActionTypes.GetLanguageResources
      ),
      mergeMap((action) =>
        this.languageService.getLanguageResourcesByLanguage(action.payload.Id).pipe(
          map(
            languageResourcesData => new BenefitActions.LoadLanguageResources({
              languageResourcesData
            })
          ),
          catchError((e) => {
            return EMPTY;
          })
        )
      )
    );
}
