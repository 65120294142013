import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RestrictInputDirective } from './components/directives/app-restrict-input.directive';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalComponent } from './components/modal/modal.component';
import { RenderBenefitsComponent } from './components/render-benefits/render-benefits.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ShoppingCartComponent,
    ModalComponent,
    RenderBenefitsComponent,
    RestrictInputDirective


  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    NgbModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ShoppingCartComponent,
    RouterModule,
    ModalComponent,
    RenderBenefitsComponent,
    RestrictInputDirective,
    TranslateModule

  ]
})
export class SharedModule { }
