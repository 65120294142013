import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, flatten } from 'lodash';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClientService } from 'src/app/core/services/client.service';
import { SurveyResponse } from 'src/models/survey';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyQuestionResponse } from 'src/models/survey-question';
import { QuestionResponse } from 'src/models/question';
import { OptionResponse } from 'src/models/option';
import { EnumSurvey } from '../shared/enums/EnumSurvey';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  surveyLoading = false;
  userCompleted = false;
  authUser: any;
  surveyModel = null;
  showFromSalary = false;
  showMenu = false;


  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.userCompleted = localStorage.getItem('userCompleted') && localStorage.getItem('userCompleted') === 'True';
    this.authUser = this.authService.getAuthUser();
    if (!this.authUser) this.router.navigateByUrl('/login');
    this.getSurveyQuestion();
  }

  getSurveyQuestion() {
    this.surveyLoading = true;
    const userId = sessionStorage.getItem('userId');
    this.clientService.getSurveyFromSendedSurvey(this.router.url, userId).subscribe(
      res => {
        if (res['Error']) this.GetErrorMessage(res['Error']);
        else {
          this.surveyModel = res['Survey'];
          this.surveyModel.SurveyQuestions.forEach(question => {
            question.Question.Options.forEach(options => {
              options['selected'] = null;
            });
          });
          this.surveyLoading = false;
        }
      });

  }

  GetErrorMessage(errorMessage) {
    const message = EnumSurvey[errorMessage];
    this.toastr.error("", this.translate.instant('survey.' + message), {
      toastClass: "ngx-toastr w-400",
      timeOut: 0
    });
    this.router.navigateByUrl('/home');

  }

  canFinish(): boolean {
    return this.surveyModel.SurveyQuestions.every(question => {
      return (question.Question.Options && question.Question.Options.length) ?
        question.Question.Options.some(opt => opt.selected) : (question.EnteredText) ? true : false;
    });
  }

  radioSelected(question: any, selectedOptionId) {
    question.Question.Options.forEach(o => {
      if (o.Id != selectedOptionId) {
        o.selected = null;
      }
    });
  }

  checkBoxSelected(event, question: any, selectedOptionId) {
    const status = <HTMLElement>event.target['checked'];
    let selectedQuestion = question.Question.Options.find(option => option.Id == selectedOptionId);
    if (status) {
      selectedQuestion.selected = selectedOptionId;
    } else {
      selectedQuestion.selected = null;
    }
  }

  finishSurvey() {
    this.surveyLoading = true;
    const response = [];
    this.surveyModel.SurveyQuestions.forEach(question => {
      let selected = [];
      selected.push(question.Question.Options.filter(option => option.selected));
      const selectedIds = selected[0].map(x => x.Id);
      response.push({
        UserId: this.authUser.userId,
        SurveyId: question.SurveyId,
        QuestionId: question.QuestionId,
        OptionValueIds: selectedIds,
        Response: (question['EnteredText']) ? question['EnteredText'] : null
      });
    });
    const payload = {
      Response: response
    };
    this.clientService.saveCompanySurvey(payload).subscribe(res => {
      if(res)
      {
        this.toastr.success("", this.translate.instant('survey.successFinish'), {
          toastClass: "ngx-toastr w-400",
          timeOut: 0
        });
      } else {
        this.toastr.error("", this.translate.instant('survey.errorFinish'), {
          toastClass: "ngx-toastr w-400",
          timeOut: 0
        });
      }
      this.router.navigate(['/home']);
    });
  }

}