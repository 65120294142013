import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, IAppConfig, APP_CONFIG } from 'src/app/core/services/app.config';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClientService } from 'src/app/core/services/client.service';
import * as BenefitActions from 'src/app/state/benefit/benefit.actions';
import { BenefitState } from 'src/app/state/benefit/benefit.reducer';
import * as CartActions from 'src/app/state/cart/cart.actions';
import { CartState } from 'src/app/state/cart/cart.reducer';
import { TranslateService } from '@ngx-translate/core';
import { BenefitCategoryResponse } from 'src/models/benefit-category';
import { BenefitResponse } from 'src/models/benefit';

@Component({
  selector: 'app-render-benefits',
  templateUrl: './render-benefits.component.html',
  styleUrls: ['./render-benefits.component.scss']
})
export class RenderBenefitsComponent implements OnInit {
  @Input() benefits: BenefitResponse[] = [];
  @Input() mainBenefits = false;
  @Input() columnClasses = 'col col-lg-3 col-md-6 col-12';
  @Input() isHealth = false;
  @Input() parentBenefit = null;
  @Input() viewType = 'card';
  selectedBenefits: number[] = [];
  selectedPackageAmount = 0;
  selectedBenefit: any = null;
  selectedPackage: any = null;
  modalVisible = false;
  isCompleted = false;
  packageModalVisible = false;
  authUser = null;
  loading = false;
  calcResults: any;
  standart = [];
  unchanged = [];
  bought = [];
  sold = [];

  constructor(
    private store: Store<{ benefit: BenefitState, cart: CartState }>,
    private clientService: ClientService,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
  ) { }

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    if (this.authUser.completed == "True") {
      this.isCompleted = true
    }
    this.isCompleted = localStorage.getItem('userCompleted') && localStorage.getItem('userCompleted') === 'True';
    /* this.store.select(state => state && state.benefit && state.benefit.benefits).subscribe(benefits => {
      if (benefits) {
        this.benefits = benefits;
      }
    });
    this.store.select(state => state && state.cart && state.cart.shoppingCart).subscribe(cart => {
      this.selectedBenefits = cart.map(x => x.Id);
    }); */

    this.store.select(state => state && state.benefit && state.benefit.data).subscribe(data => {
      // console.log(data);
      this.calcResults = data && data.calcResults ? data.calcResults : null;
      if (this.calcResults) {
        this.populateSelectedBenefits();
        this.standart = this.getBenefits(this.calcResults.groupResult.standart);
        this.unchanged = this.getBenefits(this.calcResults.groupResult.unchanged);
        this.bought = this.getBenefits(this.calcResults.groupResult.bought);
        this.sold = this.getBenefits(this.calcResults.groupResult.sold);
      }
    });
    this.store.select(state => state.benefit.addingBenefit).subscribe(benefitAdding => {
      this.loading = benefitAdding;
    });
  }

  addBenefitToCart(benefit: any) {
    let selectedHealth = null;
    this.selectedBenefits.forEach(sb => {
      if (this.benefits.findIndex(x => x.Id === sb) !== -1) {
        selectedHealth = this.benefits.find(x => x.Id === sb).Id;
      }
    });
    if (this.isHealth === true && selectedHealth) {
      this.store.dispatch(new CartActions.RemoveBenefitFromCart({ benefitId: selectedHealth }));
    }
    if (this.parentBenefit) {
      this.store.dispatch(new CartActions.AddBenefitToCart({
        benefit: {
          ...benefit,
          Name: this.parentBenefit.Name + ' ' + benefit.Name
        }
      }));
    } else {
      this.store.dispatch(new CartActions.AddBenefitToCart({ benefit }));
    }
    this.modalVisible = false;
    this.packageModalVisible = false;
  }

  onModalClose() {
    this.modalVisible = false;
    this.packageModalVisible = false;
  }

  isMultipleOptions(benefit) {
    return benefit.BenefitPackages
      && benefit.BenefitPackages.length
      && (
        benefit.BenefitPackages.length > 2
        || (
          benefit.BenefitPackages.length === 2
          && !benefit.BenefitPackages.some(pck => pck.Amount === 0)
        )
      );
  }

  removeZeroAmount(benefit) {
    // if (benefit && benefit.BenefitPackages) {
    //   benefit.BenefitPackages = benefit.BenefitPackages.sort((a, b) => b.Order - a.Order);
    // }
    return this.isMultipleOptions(benefit) ? benefit.BenefitPackages : benefit.BenefitPackages.filter(pack => pack.Amount);
  }

  sellBenefit(benefit: BenefitResponse) {
    let salesAmount = ((document.getElementById("salesAmount" + benefit.Id) as HTMLInputElement).value);
    
    let userBenefit = benefit.UserBenefits.find(x => x.BenefitId === benefit.Id);

    if(userBenefit && +benefit.salesAmount > userBenefit.Value) {
      this.toastr.warning('',
          this.translate.instant("sellAmountWarning"), {
          toastClass: 'ngx-toastr w-400'
        });
    }
    else {
      benefit.Amount = +userBenefit.Value - +salesAmount;
      this.benefitValueChanged(benefit, benefit.Amount, null, null, null, null);
    }     
  }

  benefitValueChanged(benefit, amount, marketPrice, name, remove?, packageId?) {
    if (benefit.BenefitId)
      benefit = this.benefits.find(b => b.Id == benefit.BenefitId)
    
    const clone = cloneDeep(benefit);
    
    let removed = false;
    if (!!remove && clone.Amount === amount) {
      clone.Amount = 0;
      clone.MarketPrice = 0;
      removed = true;
    } else {
      clone.Amount = amount;
      clone.MarketPrice = marketPrice;
    }
    
    if(removed) {
      packageId = 0;
    }
    
    this.store.dispatch(new BenefitActions.ToggleAddingBenefit());
    this.clientService
      .updateUserBenefit(this.authUser.userId, clone, packageId)
      .subscribe(res => {

        if (this.selectedBenefit && benefit.Id == this.selectedBenefit.Id)
          this.selectedBenefit.Amount = clone.Amount;
        
        if(benefit.Type === 'balanceUploading') {
            this.toastr.success('',
              this.translate.instant("sellAmount", { amount : benefit.Amount }), {
              toastClass: 'ngx-toastr w-400'
            });
        } 
        else {
          this.toastr.success('',
            (removed ? this.translate.instant("sell", benefit) : this.translate.instant('buy', { amount: amount, Name: benefit.Name })), {
            toastClass: 'ngx-toastr w-400'
          });
        }

        this.store.dispatch(new BenefitActions.GetData({
          userId: this.authUser.userId
        }));
        // this.loading = false;
      });
  }

  benefitTextChanged(benefit) {
    this.loading = true;
    let removed = false;
    let amount = benefit.Piece && parseInt(benefit.Piece, 10) ? parseInt(benefit.Piece, 10) * benefit.Const : benefit.Amount;

    if (!benefit.Const && (!benefit.Amount || !parseInt(benefit.Amount, 10))) { benefit.Amount = 0; removed = true; }
    if (benefit.Const && (!benefit.Piece || !parseInt(benefit.Piece, 10))) { benefit.Piece = 0; removed = true; amount = 0; }
    if ((benefit.Limit && benefit.Limit != null && benefit.Amount > benefit.Limit) || (benefit.Piece && benefit.PieceLimit != null && benefit.Piece > benefit.PieceLimit)) {      
      
      if(benefit.Limit) {
        if (benefit.Type === 'flex') {
          this.toastr.warning(benefit.Name + ' yan hakkının üst limitini aştınız.');
        } else {        
            this.toastr.warning(benefit.Name + ' yan hakkının üst limiti ' + benefit.Limit + ' TL\'dir.');
        }
      }

      if(benefit.PieceLimit) {
        this.toastr.warning(benefit.Name + ' yan hakkının üst adedi ' + benefit.PieceLimit + ' \'dir.');
        benefit.Piece = benefit.PieceLimit;
      }

      this.loading = false;
    } else {
      this.clientService
        .updateUserBenefit(this.authUser.userId, benefit, null)
        .subscribe(res => {
          if ((benefit.Limit && benefit.Amount > benefit.Limit)) {      
          // if(benefit.Piece) {
          //   this.toastr.warning(benefit.Name + ' yan hakkının üst adedi ' + benefit.Order + ' \'dir.');
          // }
           if(benefit.Limit) {
            this.toastr.warning(benefit.Name + ' yan hakkının üst limiti ' + benefit.Limit + ' TL\'dir.');
          }
        }
          else{
          this.toastr.success('',
            removed ? this.translate.instant("sell", benefit) : this.translate.instant('buy', { amount: amount, Name: benefit.Name }), {
            toastClass: 'ngx-toastr w-400'
          });}
          this.store.dispatch(new BenefitActions.GetData({
            userId: this.authUser.userId
          }));
          this.loading = false;
        });
    }
  }

  populateSelectedBenefits() {
    const ids = [];
    if (this.calcResults && this.calcResults.groupResult) {

      ['standart', 'unchanged', 'bought', 'sold'].forEach(x => {
        this.getBenefits(this.calcResults.groupResult[x]).forEach(b => {
          ids.push(b.Id);
        });
      });
    }
  }

  getBenefits(categories: BenefitCategoryResponse[]) {
    const benefits = [];
    categories.forEach(category => {
      category.Benefits.forEach(benefit => {
        if(benefit.Type === 'balanceUploading') {
          let userBenefit = benefit.UserBenefits.find(x => x.BenefitId = benefit.Id);
          if(userBenefit && userBenefit.NewValue) {
            benefit.salesAmount = +userBenefit.Value - +userBenefit.NewValue;
          } 
          else {
            benefit.salesAmount = 0;
          }         
        }

        benefits.push(benefit);
      });
    });
    return benefits;
  }

  filterFlex(benefits: BenefitResponse[]) { //console.log(benefits);
    
    benefits.filter(benefit => benefit.Type === 'balanceUploading').forEach(benefit => {
      let userBenefit = benefit.UserBenefits.find(x => x.BenefitId = benefit.Id);
      if(userBenefit && userBenefit.NewValue) {
        benefit.salesAmount = +userBenefit.Value - +userBenefit.NewValue;
      } 
      else {
        benefit.salesAmount = 0;
      }
    });    

    return benefits.filter(benefit => benefit.Type !== 'flex');
  }

  onBenefitAmountInputFocus(benefit, prop) {
      if(benefit[prop]=== 0 || benefit[prop]=== '0') {
          benefit[prop]= null;
      }
  }
  onBenefitAmountInputBlur(benefit, prop){
      if(benefit[prop]=== null) {
          benefit[prop]= 0;
      }
  }
}
