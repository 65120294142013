import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit , ElementRef, HostListener, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import * as AuthActions from 'src/app/state/auth/auth.actions';
import { AuthState } from 'src/app/state/auth/auth.reducer';
import { CompanyResponse } from 'src/models/company';
import { Credentials } from 'src/models/credentials';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    credentials: Credentials = { tckno: '', password: '', companyId : '', rememberMe: false };
    error = '';
    returnUrl: string | null = null;
    authUser: any;
    subscriptions: Subscription[] = [];
    loading = false;
    languageVisible = false;
    selectedLanguage: string;

    public companies: CompanyResponse[];
    selectedCompanyId : string = "0";
    selectedCompanyName : string = "";
    @ViewChild('colorInput', { static: false }) colorInput: ElementRef;
    private timer: any;
    constructor(
        private authService: AuthService,
        private companyService: CompanyService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<{ auth: AuthState }>,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        let userLang = localStorage.getItem('userLang') || this.translateService.getBrowserLang() || 'tr';
        if (['tr', 'en'].indexOf(userLang) === -1) {
            userLang = this.translateService.getBrowserLang() || 'tr';
        } 
        
        this.selectedLanguage = userLang.substring(0, 2);
        this.changeLanguage(this.selectedLanguage);
        this.store.select(state => state.auth.errorMessage).subscribe(errorMessage => {
           // console.log(errorMessage);
            if (errorMessage && errorMessage.length !== 0) {
                console.log(errorMessage)
                this.error = errorMessage;
            }
        });
        // reset the login status
        this.authService.logout(false);

        // get the return url from route parameters
        this.returnUrl = this.route.snapshot.queryParams.returnUrl;

    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    changeTCNo(event) {
        if(event.target.value.length == 11) {
            this.getCompanies(event.target.value);
        }
        else {
            this.companies = null;
        }

    }

    getCompanies(tcNo : string){
        this.companyService.getCompanies(tcNo).subscribe(
            data => {
                this.companies = data as CompanyResponse[];
                this.selectedCompanyId = this.companies[0].Id;
                this.selectedCompanyName = this.companies[0].Name;
            }
        );
    }

    login() {
        this.loading = true;
        // this.error = '';
        console.log(this.error)
        if(this.selectedCompanyId === "0") {
            this.error = this.translateService.instant('login.selectValidCompany');
            this.loading = false;
            return;
        }
        if (this.credentials.tckno.length !== 11) {
            this.error = this.translateService.instant('login.identityError');
            this.loading = false;
            return;
        }
        
        this.credentials.companyId = this.selectedCompanyId;
        console.log("companyId : " + this.credentials.companyId);

        this.store.dispatch(new AuthActions.Login({ credentials: { ...this.credentials } }));
        console.log("credentials",this.credentials)
        if(this.selectedCompanyId !== "0") {
            let status: CompanyResponse = this.companies.find(s => s.Id == this.selectedCompanyId);
                if (status)
                    this.selectedCompanyName = status.Name;
        }
        console.log(this.selectedCompanyId);
        (error: HttpErrorResponse) => {
            this.loading=false;
            console.error("Login error", error);
            if (error.status === 401) {
                this.error = "T.C. Kimlik No./Şifre hatalı";
            } else {
                this.error = `${error.statusText}: ${error.message}`;
            }
        };
        this.authService.login(this.credentials)
            .subscribe(isLoggedIn => {
                this.loading = false;
                if (isLoggedIn) {
                    this.authUser = this.authService.getAuthUser();
                    // if (this.authUser.completed=="True") {
                    //     this.router.navigate(["/completed"]);
                    // }
                    if (this.authUser.loggedinBefore != 'True') {
                        this.router.navigate(['/help']);
                    } else {
                        this.router.navigate(['/home']);
                    }
                }
            },
                (error: HttpErrorResponse) => {
                    this.loading = false;
                    console.error('Login error', error);
                    if (error.status === 401) {
                        this.error = 'T.C. Kimlik No./Şifre hatalı';
                    } else {
                        this.error = `${error.statusText}: ${error.message}`;
                    }
                });
    }

    toggleDropdown() {
        this.languageVisible = !this.languageVisible;
    }

    changeLanguage(code: string): void {
        this.translateService.setDefaultLang(code);
        localStorage.setItem('userLang', code);
        this.selectedLanguage = code;
        this.languageVisible = false;
    }

    @HostListener('input', ['$event.target'])
    onInput(el: HTMLInputElement): void {
      if (el.dataset.color) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          document.documentElement.style.setProperty(`--color-${el.dataset.color}`, el.value);
        }, 100);
      }
    }

}




