import { Component, OnInit, NgModule } from "@angular/core";
import { TabsModule } from "ngx-bootstrap/tabs";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"]
})
@NgModule({
  imports: [TabsModule.forRoot()]
})
export class UserSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
