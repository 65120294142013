import { Action } from '@ngrx/store';
import { AuthUser } from 'src/models/auth-user';
import { Credentials } from 'src/models/credentials';

export enum ActionTypes {
    GetAuthUser = '[AUTH] GET_AUTH_USER',
    AuthUserLoaded = '[AUTH] AUTH_USER_LOADED',
    StoreAuthUser = '[AUTH] STORE_AUTH_USER',
    Login = '[AUTH] Login',
    Logout = '[AUTH] Logout',
    ErrorOccurred = '[AUTH] ERROR_OCCURRED',
    SetDefaultLanguage = '[AUTH] SET_DEFAULT_LANGUAGE'
}

export class GetAuthUser implements Action {
    readonly type = ActionTypes.GetAuthUser;
}

export class Login implements Action {
    readonly type = ActionTypes.Login;
    constructor(public payload: { credentials: Credentials }) { }
}

export class Logout implements Action {
    readonly type = ActionTypes.Logout;
}

export class AuthUserLoaded implements Action {
    readonly type = ActionTypes.AuthUserLoaded;
    constructor(public payload: { authUser: AuthUser }) { }
}

export class StoreAuthUser implements Action {
    readonly type = ActionTypes.StoreAuthUser;
    constructor(public payload: { authUser: AuthUser }) { }
}

export class ErrorOccurred implements Action {
    readonly type = ActionTypes.ErrorOccurred;
    constructor(public payload: { message: string }) { }
}

export class SetDefaultLanguage implements Action {
    readonly type = ActionTypes.SetDefaultLanguage;
    constructor(public payload: { defaultLanguage: any }) { }
}

export type Actions =
    GetAuthUser
    | Login
    | Logout
    | AuthUserLoaded
    | StoreAuthUser
    | ErrorOccurred
    | SetDefaultLanguage;
