export class BasicBenefitsTextResponse {
    Id: string;
    CompanyId: string;
    Description: string;
}

export class BasicBenefitsTextRequest {
    Id: string;
    CompanyId: string;
    Description: string;
}