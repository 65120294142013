import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/services/auth.guard';
import { ShoppingCartRightComponent } from '../layout/components/shopping-cart-right/shopping-cart-right.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FlexibleRightContentComponent } from './components/flexible-right-content/flexible-right-content.component';
import { FlexibleRightComponent } from './components/flexible-right/flexible-right.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';

const routes: Routes = [
  {
    path: '',
    component: ShoppingCartRightComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'temel-yan-haklar',
        component: DashboardComponent,
        data: {
          title: 'Temel Yan Haklar',
          completed: false
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'home/category/:categoryId',
        component: FlexibleRightContentComponent,
        data: {
          title: 'Temel Yan Haklar',
          completed: false
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'esnek-yan-haklar',
        component: FlexibleRightComponent,
        data: {
          title: 'Esnek Yan Haklar',
          completed: false
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'esnek-yan-haklar/kategori/:categoryId',
        component: FlexibleRightContentComponent,
        data: {
          title: 'Sağlık ve Güvence',
          completed: false
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'ayarlar',
        component: UserSettingsComponent,
        data: {
          title: 'Ayarlar'
        }
      },

    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
