import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BenefitResponse } from 'src/models/benefit';
import { BenefitCategoryResponse } from 'src/models/benefit-category';
import { APP_CONFIG, IAppConfig } from '../core/services/app.config';

@Injectable({
  providedIn: 'root'
})
export class BenefitService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: IAppConfig
  ) { }

  getBenefits(): Observable<any[]> {
    return this.http.get<any[]>('/assets/mocked/benefits.json').pipe(
      map(res => res)
    );
  }

  getBenefitCategories({ companyId, baseCategories, parentId }): Observable<BenefitCategoryResponse[]> {
    // tslint:disable-next-line: max-line-length
    const url = `${this.appConfig.apiEndpoint}/benefit/getBenefitCategories?companyId=${companyId}&baseCategories=${baseCategories}&parentId=${parentId}`;
    return this.http.get<BenefitCategoryResponse[]>(url).pipe(
      map(res => res)
    );
  }

  getBenefitTypes(): Observable<any[]> {
    return this.http.get<any[]>('/assets/mocked/benefit-types.json').pipe(
      map(res => res)
    );
  }

  getMainBenefits(companyId: number): Observable<BenefitResponse[]> {
    return this.http.get<BenefitResponse[]>(`${this.appConfig.apiEndpoint}/benefit/getMainBenefits?companyId=${companyId}`).pipe(
      map(res => res)
    );
  }

  getBenefitCategory(categoryId: number): Observable<BenefitCategoryResponse> {
    const url = `${this.appConfig.apiEndpoint}/benefit/getBenefitCategory?id=${categoryId}`;
    return this.http.get<BenefitCategoryResponse>(url).pipe(
      map(res => res)
    );
  }

  getUserGiftCardRule(userId: number): Observable<any> {
    const url = `${this.appConfig.apiEndpoint}/benefit/getUserGiftCardRule?userId=${userId}`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  getBenefitsByCategory(categoryId: number): Observable<BenefitResponse[]> {
    const url = `${this.appConfig.apiEndpoint}/benefit/getBenefitsByCategory?benefitCategoryId=${categoryId}`;
    return this.http.get<BenefitResponse[]>(url).pipe(
      map(res => res)
    );
  }

  allCategories(companyId: any) {
    const url = `${this.appConfig.apiEndpoint}/benefit/allCategories?companyId=${companyId}`;
    return this.http.get<BenefitCategoryResponse[]>(url).pipe(
      map(res => res)
    );
  }
}
