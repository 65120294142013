import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from './core/services/auth.service';
import { ClientService } from './core/services/client.service';
import { LanguageService } from './core/services/language.service';
import { GetAuthUser, SetDefaultLanguage } from './state/auth/auth.actions';
import { AuthState } from './state/auth/auth.reducer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';
  user: any;

  constructor(
    private store: Store<AuthState>,
    private clientService: ClientService,
    private authService: AuthService,
    private languageService: LanguageService
  ) {
    this.getAuthUser();
  }

  getAuthUser() {
    /* get authenticated user from localStorage to state */
    this.store.dispatch(new GetAuthUser());
    this.user = this.authService.getAuthUser();
    if (!this.user) {
      this.authService.logout(true);
    }
    if (this.user) {
      this.setDefaultLanguage();
    }
  }

  setDefaultLanguage() {
    this.languageService.getLanguage(this.user.languageId).subscribe(res => {
      this.store.dispatch(new SetDefaultLanguage({ defaultLanguage: res }));
    });
  }

}

