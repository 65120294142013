import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { AnnouncementResponse } from 'src/models/announcement';
import { AuthTokenType } from 'src/models/auth-token-type';
import { BenefitRequest } from 'src/models/benefit';
import { BenefitCategoryResponse } from 'src/models/benefit-category';
import { UserBenefitResponse } from 'src/models/user-benefit';
import { UserStorage } from 'src/models/user-storage';
import { ApiConfigService } from './api-config.service';
import { APP_CONFIG, IAppConfig } from './app.config';
import { TokenStoreService } from './token-store.service';
import { KvkkTextResponse } from 'src/models/kvkk-text';
import { TermsTextResponse } from 'src/models/terms-text';
import { HomeContentResponse } from 'src/models/home-content';
import { ConsentResponse } from 'src/models/consent';
import { CommitmentResponse } from 'src/models/commitment';
import { BasicBenefitsTextResponse } from 'src/models/basic-benefits-text';
import { FlexibleBenefitsTextResponse } from 'src/models/flexible-benefits-text';


@Injectable()
export class ClientService {

  private headers = new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) {
    this.headers = this.getBearerAuthHeader();
  }


  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }

  getData(userId: string, completed: boolean): Observable<BenefitCategoryResponse[]> {
    this.apiPath = `${this.appConfig.apiEndpoint}/client/getData?userId=${userId}&completed=${completed}`;
    return this.http.get<BenefitCategoryResponse[]>(this.apiPath, { headers: this.headers });
  }

  getDataWithCalc(userId: string): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/client/getDataWithCalc?userId=${userId}`;
    return this.http.get<any>(this.apiPath, { headers: this.headers });
  }
  getAnnouncement(companyId: string): Observable<AnnouncementResponse> {
    this.apiPath = `${this.appConfig.apiEndpoint}/company/getActiveAnnouncements?companyId=${companyId}`;
    return this.http.get<AnnouncementResponse>(this.apiPath, { headers: this.headers });
  }
  
  getKvkkTextByCompany(companyId: string): Observable<KvkkTextResponse> {
    this.apiPath = `${this.appConfig.apiEndpoint}/company/getKvkkTextByCompany?companyId=${companyId}`;
    return this.http.get<KvkkTextResponse>(this.apiPath, { headers: this.headers });
  }
  getCompanyGiftCardFM(companyId: string):Observable<any>{
    this.apiPath = `${this.appConfig.apiEndpoint}/company/getCompanyGiftCardFM?id=${companyId}`;
    return this.http.get<any>(this.apiPath, { headers: this.headers });
  }

  getTermsTextByCompany(companyId: string): Observable<TermsTextResponse> {
    this.apiPath = `${this.appConfig.apiEndpoint}/company/getTermsTextByCompany?companyId=${companyId}`;
    return this.http.get<TermsTextResponse>(this.apiPath, { headers: this.headers });
  }

  getHomeContentByCompany(companyId: string): Observable<HomeContentResponse> {
    this.apiPath = `${this.appConfig.apiEndpoint}/company/getHomeContentByCompany?companyId=${companyId}`;
    return this.http.get<HomeContentResponse>(this.apiPath, { headers: this.headers });
  }

  getConsentByCompany(companyId: string): Observable<ConsentResponse> {
    this.apiPath = `${this.appConfig.apiEndpoint}/company/getConsentByCompany?companyId=${companyId}`;
    return this.http.get<ConsentResponse>(this.apiPath, { headers: this.headers });
  }

  getCommitmentByCompany(companyId: string): Observable<CommitmentResponse> {
    this.apiPath = `${this.appConfig.apiEndpoint}/company/getCommitmentByCompany?companyId=${companyId}`;
    return this.http.get<CommitmentResponse>(this.apiPath, { headers: this.headers });
  }

  getBasicBenefitsTextByCompany(companyId: string): Observable<BasicBenefitsTextResponse> {
    this.apiPath = `${this.appConfig.apiEndpoint}/company/getBasicBenefitsTextByCompany?companyId=${companyId}`;
    return this.http.get<BasicBenefitsTextResponse>(this.apiPath, { headers: this.headers });
  }

  getFlexibleBenefitsTextByCompany(companyId: string): Observable<FlexibleBenefitsTextResponse> {
    this.apiPath = `${this.appConfig.apiEndpoint}/company/getFlexibleBenefitsTextByCompany?companyId=${companyId}`;
    return this.http.get<FlexibleBenefitsTextResponse>(this.apiPath, { headers: this.headers });
  }

  sendPassword(email: string): Observable<boolean> {
    this.apiPath = `${this.appConfig.apiEndpoint}/email/sendPassword?TCKNO=${email}`;
    return this.http.post<boolean>(this.apiPath, { headers: this.headers });
  }

  saveUserBenefits(userId: string, benefitCategories: Array<any>): Observable<boolean> {
    this.apiPath = `${this.appConfig.apiEndpoint}/user/saveUserBenefits?userId=${userId}`;
    return this.http.post<boolean>(this.apiPath, JSON.stringify(benefitCategories), { headers: this.headers });
  }

  updateUserBenefit(userId: string, benefit: BenefitRequest, selectedPackageId): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/client/updateUserBenefit?userId=${userId}&selectedPackageId=${selectedPackageId}`;
    return this.http.post<UserBenefitResponse>(this.apiPath, JSON.stringify(benefit), { headers: this.headers });
  }

  clientError(message:string): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/client/clientError`;
    return this.http.post<UserBenefitResponse>(this.apiPath, JSON.stringify((message)), { headers: this.headers });
  }

  restartUserBenefits(userId: string): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/client/restartUserBenefits?userId=${userId}`;
    return this.http.post<UserBenefitResponse>(this.apiPath, null, { headers: this.headers });
  }

  getGiftCalc(userId:number, giftLimit: number, monthLimt:number, giftModel: any): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/client/getGiftCalc?userId=${userId}&giftLimit=${giftLimit}&monthLimit=${monthLimt}`;
    return this.http.post<UserBenefitResponse>(this.apiPath, JSON.stringify(giftModel), { headers: this.headers });
  }

  generateUserGiftReport(userId: string): Observable<UserBenefitResponse[]> {
    this.apiPath = `${this.appConfig.apiEndpoint}/benefit/generateUserGiftReport?userId=${userId}`;
    return this.http.get<UserBenefitResponse[]>(this.apiPath, { headers: this.headers });
  }

  updateUserStorage(userStorage: UserStorage, sync): Observable<UserStorage> {
    this.apiPath = `${this.appConfig.apiEndpoint}/client/updateUserStorage`;
    if (sync) {
      const xhr = new XMLHttpRequest()
      xhr.open('post', `${this.appConfig.apiEndpoint}/client/updateUserStorage`, false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`);
      xhr.send(JSON.stringify(userStorage));
    } else {
      return this.http.post<UserStorage>(this.apiPath, JSON.stringify(userStorage), { headers: this.headers });
    }
  }

  getUserStorage(userId: string): Observable<UserStorage> {
    this.apiPath = `${this.appConfig.apiEndpoint}/client/getUserStorage?userId=${userId}`;
    return this.http.get<UserStorage>(this.apiPath, { headers: this.headers });
  }

  getUserCompleted(userId: string): Observable<boolean> {
    this.apiPath = `${this.appConfig.apiEndpoint}/user/getUserCompleted?userId=${userId}`;
    return this.http.get<boolean>(this.apiPath, { headers: this.headers });
  }

  getCompanySurvey(companyId: string): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/survey/getSurveyClient?companyId=${companyId}`;
    return this.http.get<boolean>(this.apiPath, { headers: this.headers }).pipe(
      map(res => res),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getSpesificSurvey(surveyId: number): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/survey/getSpesificSurvey?surveyId=${surveyId}`;
    return this.http.get<boolean>(this.apiPath, { headers: this.headers }).pipe(
      map(res => res),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getSurveyFromSendedSurvey(createdLink: string, userId: string): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/survey/getSurveyFromSendedSurvey?createdLink=${createdLink}&userId=${userId}`;
    return this.http.get<boolean>(this.apiPath, { headers: this.headers }).pipe(
      map(res => res),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  saveCompanySurvey(payload): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/survey/saveUserResponse`;
    return this.http.post<boolean>(this.apiPath, payload, { headers: this.headers }).pipe(
      map(res => res),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getUserUndertakings(userId: number): Observable<any> {
    this.apiPath = `${this.appConfig.apiEndpoint}/survey/getSpesificSurvey?userId=${userId}`;
    return this.http.get<boolean>(this.apiPath, { headers: this.headers }).pipe(
      map(res => res),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  getPeriod(CompanyId:number, languageAbb:string): Observable<any>{
    this.apiPath=`${this.appConfig.apiEndpoint}/period/getPeriodsByCompanyId?companyId=${CompanyId}&languageAbb=${languageAbb}`;
    return this.http.get<any>(this.apiPath, { headers: this.headers }).pipe(
      map(res => res),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
