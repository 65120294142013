import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page-settings',
  templateUrl: './home-page-settings.component.html',
  styleUrls: ['./home-page-settings.component.scss']
})
export class HomePageSettingsComponent implements OnInit {
  showFromSalary = false;

  constructor() { }

  ngOnInit() {
    
  }

}
