export class HomeContentResponse {
    Id: string;
    CompanyId: string;
    Description: string;
    ImgBasicBenefits:string; 
    ImgFlexibleBenefits:string; 
}

export class HomeContentRequest {
    Id: string;
    CompanyId: string;
    Description: string;
    ImgBasicBenefits:string; 
    ImgFlexibleBenefits:string; 
}