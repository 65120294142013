import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/core/services/client.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    success: boolean = false;
    error: boolean = false;
    errorMessage: string = "";
    email: string;
    public loading = false;
    languageVisible = false;
    selectedLanguage: string;

    constructor(
        private clientService: ClientService,
        private translateService: TranslateService,
    ) { }

    sendPassword() {
        if (this.email != "") {
            this.loading = true;
            this.clientService.sendPassword(this.email).subscribe(x => {
                this.loading = false;
                if (x == true) {
                     this.success = true;
                     this.error = false;
                }
            },(error: HttpErrorResponse)=> {
                this.error = true;
                this.success = false;
                this.errorMessage = error.error;
            });
        }
    }

    ngOnInit() {
        let userLang = localStorage.getItem('userLang') || this.translateService.getBrowserLang() || 'tr';
        if (['tr', 'en'].indexOf(userLang) === -1) {
            userLang = this.translateService.getBrowserLang() || 'tr';
        }
        this.selectedLanguage = userLang.substring(0, 2);
        this.changeLanguage(this.selectedLanguage);
    }

    toggleDropdown() {
        this.languageVisible = !this.languageVisible;
    }

    changeLanguage(code: string): void {
        this.translateService.setDefaultLang(code);
        localStorage.setItem('userLang', code);
        this.selectedLanguage = code;
        this.languageVisible = false;
    }

}




