import { CommonModule, DecimalPipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';
import { ApiConfigService } from "./services/api-config.service";
import { AppConfig, APP_CONFIG } from "./services/app.config";
import { AuthGuard } from "./services/auth.guard";
import { AuthInterceptor } from "./services/auth.interceptor";
import { AuthService } from "./services/auth.service";
import { BrowserStorageService } from "./services/browser-storage.service";
import { ClientService } from "./services/client.service";
import { ExcelService } from './services/excel.service';
import { ModalService } from './services/modal.service';
import { PageService } from "./services/page.service";
import { RefreshTokenService } from "./services/refresh-token.service";
import { TokenStoreService } from "./services/token-store.service";
import { UtilsService } from "./services/utils.service";




@NgModule({
  imports: [CommonModule, RouterModule,],
  exports: [
    TranslateModule
  ],
  declarations: [
  ],
  providers: [
    // global singleton services of the whole app will be listed here.
    UtilsService,
    BrowserStorageService,
    TokenStoreService,
    ClientService,
    ModalService,
    PageService,
    ExcelService,
    RefreshTokenService,
    {
      provide: APP_CONFIG,
      useValue: AppConfig
    },
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ApiConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ApiConfigService) => () => config.loadApiConfig(),
      deps: [ApiConfigService],
      multi: true
    },
    DecimalPipe,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error("CoreModule should be imported ONLY in AppModule.");
    }
  }
}
