import { Action } from '@ngrx/store';

export enum ActionTypes {
    AddBenefitToCart = '[CART] ADD_BENEFIT_TO_CART',
    RemoveBenefitFromCart = '[CART] REMOVE_BENEFIT_FROM_CART',
    ToggleMobileCart = '[CART] TOGGLE_MOBILE_CART',
    PopulateCartWithMainBenefits = '[CART] POPULATE_CART_WITH_MAIN_BENEFITS'
}

export class AddBenefitToCart implements Action {
    readonly type = ActionTypes.AddBenefitToCart;
    constructor(public payload: { benefit: any }) { }
}

export class RemoveBenefitFromCart implements Action {
    readonly type = ActionTypes.RemoveBenefitFromCart;
    constructor(public payload: { benefitId: any }) { }
}

export class ToggleMobileCart implements Action {
    readonly type = ActionTypes.ToggleMobileCart;
    constructor() { }
}

export class PopulateCartWithMainBenefits implements Action {
    readonly type = ActionTypes.PopulateCartWithMainBenefits;
    constructor(public payload: { benefits: any[] }) { }
}

export type Actions =
    AddBenefitToCart
    | RemoveBenefitFromCart
    | ToggleMobileCart
    | PopulateCartWithMainBenefits;
