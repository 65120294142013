import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { SurveyComponent } from './survey.component';
import { SurveyRoutingModule } from './survey-routing.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SurveyComponent],
  imports: [
    CommonModule,
    SharedModule,
    SurveyRoutingModule,
    FormsModule
  ]
})
export class SurveyModule { }
