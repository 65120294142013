import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-right',
  templateUrl: './settings-right.component.html',
  styleUrls: ['./settings-right.component.scss']
})
export class SettingsRightComponent implements OnInit {
  showFromSalary = true;

  constructor() { }

  ngOnInit() {
    this.showFromSalary = !(localStorage.getItem('userCompleted') && localStorage.getItem('userCompleted') === 'True');
  }

}
