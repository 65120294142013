import { InjectionToken, Injectable } from '@angular/core';
import * as appConfig from "src/assets/app.config.json"
import { inject } from '@angular/core/testing';

export let APP_CONFIG = new InjectionToken<string>('app.config');


export interface IAppConfig {
  apiEndpoint: string;
  apiSettingsPath: string;
  storageUrl: string;
}


@Injectable()
export class AppConfig{
  public static apiEndpoint: string;
  public static apiSettingsPath: string;
  public static storageUrl: string;
}