import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Output() modalClose: EventEmitter<any> = new EventEmitter();
  @Input() banner: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  onModalClose() {
    this.modalClose.emit();
  }
  backdropClick(e) {
    if (e.target === document.getElementById('app-modal-global')) {
      this.modalClose.emit();
    }
  }

}
