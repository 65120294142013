import {Directive, ElementRef, Input} from '@angular/core';
import * as Inputmask from 'inputmask';


@Directive({
  selector: '[app-restrict-input]',
})
export class RestrictInputDirective {

  private regexMap = {
    integer: '^[0-9]*$',
    integer500: '^0*([0-9]|[1-8][0-9]|9[0-9]|[1-4][0-9]{2}|500)$',
    float: '^([0-9]*[.])?[0-9]{1,2}$'
  };

  constructor(private el: ElementRef) {}

  @Input('app-restrict-input')
  public set defineInputType(type: string) {
    Inputmask({regex: this.regexMap[type], placeholder: '0'})
      .mask(this.el.nativeElement);
  }

}