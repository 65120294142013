import { Component, Inject, OnInit, VERSION} from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClientService } from 'src/app/core/services/client.service';
import { HomeContentResponse } from 'src/models/home-content';
import { APP_CONFIG, IAppConfig } from 'src/app/core/services/app.config';
import { NgbCarouselConfig, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HomePageComponent implements OnInit {
  authUser: any;
  
  homeContentModel = new HomeContentResponse();
  homeContentText: string;
  imgBasicBenefits: string;
  imgFlexibleBenefits: string;
  public loading = false;
  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  name = 'Angular ' + VERSION.major;
  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    @Inject(APP_CONFIG) public appConfig: IAppConfig,
    config: NgbCarouselConfig
  ) { 
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }
  
  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    this.getHomeContentByCompany();
  }

  getHomeContentByCompany() {
    this.loading = true;
    this.clientService.getHomeContentByCompany(this.authUser.companyId).subscribe(data => {
      this.homeContentModel = data as HomeContentResponse;
      this.loading = false;
      this.homeContentText = this.homeContentModel ? this.homeContentModel.Description : "";
      this.imgBasicBenefits = this.homeContentModel ? this.homeContentModel.ImgBasicBenefits : "";
      this.imgFlexibleBenefits = this.homeContentModel ? this.homeContentModel.ImgFlexibleBenefits : "";
    })    
  } 
}
