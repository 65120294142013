export class FlexibleBenefitsTextResponse {
    Id: string;
    CompanyId: string;
    Description: string;
}

export class FlexibleBenefitsTextRequest {
    Id: string;
    CompanyId: string;
    Description: string;
}