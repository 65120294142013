import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { AuthTokenType } from 'src/models/auth-token-type';
import { TokenStoreService } from './token-store.service';
import { ClientService } from './client.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private tokenStoreService: TokenStoreService,
    private router: Router,
    private clientService: ClientService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken);
    if (accessToken) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${accessToken}`)
      });
      if(/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)){
        request = request.clone({
          headers:request.headers.set("Pragma", "no-cache")
        });
        request = request.clone({
          headers:request.headers.set("Cache-Control", "no-cache")
        });
        request = request.clone({
          headers:request.headers.set("Expires", new Date(new Date().setSeconds(new Date().getSeconds() - 10)).toISOString())
        });
      }
      return next.handle(request).pipe(
        catchError((error: any, caught: Observable<HttpEvent<any>>) => {
          console.error({ error, caught });
          this.clientService.clientError(error.message).subscribe(response => {
          });
          if (error.status === 401 || error.status === 403) {
            this.router.navigate(['/login']);
          }
          return throwError(error);
        })
      );
    } else {
      // login page
      return next.handle(request);
    }
  }
}
